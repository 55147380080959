import React from "react";
import { Jumbotron, Container, Row, Col } from "react-bootstrap";
import Img from "../img/logo.png";
import { AboutUs } from "./AboutUs";

export const AboutBill = () => {
  return (
    <Jumbotron fluid>
      <Container>
        <Row>
          <Col md={6}>
            <img width={"100%"} src={Img} style={{ background: "gray" }} />
          </Col>
          <Col style={{ paddingTop: "30px" }}>
            <h1>About Bill 104 </h1>
            <p style={{ paddingTop: "30px" }}>
              Bill 104, Tamil Genocide Education Week, an act to proclaim a
              seven-day period each year, from May 11th-18th as Tamil Genocide
              Education Week. During that period, all Ontarians are encouraged
              to educate themselves about, and to maintain their awareness of,
              the Tamil genocide and other genocides that have occurred in world
              history.
            </p>
          </Col>
        </Row>
        <Row>
          <AboutUs />
        </Row>
      </Container>
    </Jumbotron>
  );
};
