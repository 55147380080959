import React from "react";
import { Tab, Nav, Col, Row } from "react-bootstrap";
import { EndStu, EndParents, EndEdu } from "./endorsements";

export const Endose = () => {
  return (
    <div style={{ paddingTop: "70px" }}>
      <center>
        <h1>Endosement</h1>

        <h4>Organization</h4>
        <table>
          <tr>
            <tr>Aanmaa Yoga</tr>
            <tr>Aathi Silambam</tr>
            <tr>Abinayalaya Natyalayam Dance Acedemy</tr>
            <tr>Amirthalaya Academy of Fine Arts</tr>
            <tr>Aram Foundations</tr>
            <tr>Canadian Academy of Tamil Folk Arts</tr>
            <tr>Canadian Mother Tamil Forum</tr>
            <tr>Canadian Tamil Sangam</tr>
            <tr>Durham Tamil Association</tr>
            <tr>Inuvil Thiruvoor Ontriam - Canada</tr>
            <tr>Kalaikovil Academy of Fine Arts </tr>
            <tr>Khaviyalaya Nadana Pally</tr>
            <tr>Maple Cricket Club</tr>
            <tr>Markham Tamil Organization</tr>
            <tr>Organization of Canadian Tamils with Disabilities</tr>
            <tr>Our TYM: Our Tamil Youth in Motion</tr>
            <tr>Queen’s University Tamil Students Association</tr>
            <tr>South Asian Legal Clinic of Ontario</tr>
            <tr>South Asian Mental Health Alliance</tr>
            <tr>Starz Dance</tr>
            <tr>Tamil Canadian Centre for Civic Action</tr>
            <tr>Tamil Canadian Sports and Culture</tr>
            <tr>Tamil Cultural Academic Society of Durham </tr>
            <tr>Tamil Memorial Foundation</tr>
            <tr>Tamil Workers Network</tr>
            <tr>Tamilar Aram</tr>
            <tr>Thamil Poonka School</tr>
            <tr>Transnational Government of Tamil Eelam</tr>
            <tr>Yogasalai</tr>
          </tr>
        </table>
      </center>

      {/* <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="first">Parents</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="thired">Educators and Researchers</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="second">
                <EndStu />
              </Tab.Pane>
              <Tab.Pane eventKey="first">
                <EndParents />
              </Tab.Pane>
              <Tab.Pane eventKey="thired">
                <EndEdu />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container> */}
    </div>
  );
};
