import React, { useEffect, useState } from "react";
import { Container, Jumbotron } from "react-bootstrap";
import Faq from "react-faq-component";
import useFetch from "../component/useFetch";

export const FAQ = () => {
  // const { data } = useFetch("https://api.npoint.io/cdb817bdd50539355954");
  const [data, setData] = useState("");
  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        "https://api.npoint.io/cdb817bdd50539355954"
      );
      setData(await response.json());
    }
    fetchData();
  }, []);

  return (
    <Container>
      <h1>FAQ</h1>
      <Faq
        tabFocus
        data={data["faq"]}
        styles={data["styles"]}
        config={config}
      />
    </Container>
  );
};

const config = {
  // animate: true,
  // arrowIcon: "V",
  // tabFocus: true
};
