import React, { useState, useEffect } from "react";
import { Jumbotron, Container } from "react-bootstrap";
import { Chrono } from "react-chrono";

import "./purpose.css";

export const Purpose = () => {
  const [data, setData] = useState("");
  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        "https://api.npoint.io/7fd95f141a23b0944774"
      );
      setData(await response.json());
    }
    fetchData();
  }, []);
  return (
    <div fluid>
      <Container style={{ padding: "10px" }}>
        <h1>Purpose</h1>
        It is important for many reasons to acknowledge publicly that the
        killings and all aspects of the genocide constitute a heinous act. Not
        only does this provides an opportunity for to honour and memorialize the
        lives that were lost, but it gives a sense of hope to those who have
        suffered since it represents the first step to healing and
        reconciliation and commitment to supporting long term peace
        accountability. Most importantly, by recognizing the Tamil genocide,
        affirm collective desire to maintain awareness of this genocide and
        other genocides that have occurred in world history in order to prevent
        such crimes against humanity is never repeated and to continue to seek
        justice.
      </Container>
      <Container>
        {data === "" ? (
          <div>loading</div>
        ) : (
          <div style={{ width: "100%" }}>
            <Chrono
              style={{ width: "100%" }}
              items={data}
              mode="VERTICAL"
              hideControls
            />
          </div>
        )}
      </Container>
    </div>
  );
};
