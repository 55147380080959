import React, { useState } from "react";
import { Navbar, NavDropdown, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagramSquare,
} from "@fortawesome/free-brands-svg-icons";
import { PopModel } from "./PopModel";
import logo from "../../img/logo.png";
import { useLocation, Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import "./Nav.css";
export const NavBar = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(!show);
  let active = useLocation().pathname;

  return (
    <Navbar collapseOnSelect expand="lg" bg="danger" variant="dark" fixed="top">
      <Navbar.Brand style={{ paddingLeft: "60px" }}>
        <img style={{ width: "80px" }} src={logo} />
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          {/* <LinkContainer to="/">
            <Nav.Link as={Link} to="/AboutBill">
              About Bill 104{" "}
            </Nav.Link>
          </LinkContainer> */}
        </Nav>

        <Nav>
          <LinkContainer to="/home">
            <Nav.Link>Bill 104 </Nav.Link>
          </LinkContainer>
          <LinkContainer to="/purpose">
            <Nav.Link>Purpose</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/endosement">
            <Nav.Link>Endorsements</Nav.Link>
          </LinkContainer>
          {/* <NavDropdown title="Endorsements" id="collasible-nav-dropdown">
            <NavDropdown.Item href="/endosement#1">Students</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">Parents</NavDropdown.Item>
            <NavDropdown.Item href="action/3.3">
              Educators and Researchers
            </NavDropdown.Item>
           
            <NavDropdown.Item href="">Organizations</NavDropdown.Item>
            <NavDropdown.Item href="">Artist </NavDropdown.Item>
            <NavDropdown.Item href="">Businesses </NavDropdown.Item>
            <NavDropdown.Item href="">
              Legal and Health Professionals
            </NavDropdown.Item>
            <NavDropdown.Item href=""> Allies </NavDropdown.Item>
            <NavDropdown.Item href="">
              Media and Elected Representatives
            </NavDropdown.Item>
            <NavDropdown.Item href="">Community Services</NavDropdown.Item>
          </NavDropdown> */}
          <LinkContainer to="/news">
            <Nav.Link>News</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/FAQ">
            <Nav.Link as={Link}>FAQ</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/aboutUs">
            <Nav.Link>Contact Us</Nav.Link>
          </LinkContainer>
          <div
            style={{
              display: "flex",
              padding: "0 50px",
              justifyContent: "space-evenly",
            }}>
            <Nav.Link onClick={handleShow}>
              <PopModel showModal={show} />
            </Nav.Link>

            <Nav.Link href="https://www.facebook.com/coalition104 ">
              <FontAwesomeIcon size="2x" icon={faFacebookSquare} />
            </Nav.Link>
            <Nav.Link href="https://www.instagram.com/coalition104/ ">
              <FontAwesomeIcon size="2x" icon={faInstagramSquare} />
            </Nav.Link>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
