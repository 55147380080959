import { NavBar } from "./component/nav/Nav";
import { Purpose, AboutUs, AboutBill, Home, Endose } from "./pages";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { FAQ } from "./pages/FAQ";
import { News } from "./pages/News";
function App() {
  return (
    <div className="App">
      <Router>
        <header className="App-">
          <NavBar />
        </header>
        <div className="main">
          <Switch>
            <Route exact path="/purpose">
              <Purpose />
            </Route>
            <Route exact path="/aboutUs">
              <AboutUs />
            </Route>
            <Route exact path="/endosement">
              <Endose />
            </Route>
            {/* <Route exact path="/">
              <Home />
            </Route> */}
            <Route exact path="/home">
              <AboutBill />
            </Route>
            <Route exact path="/">
              <Redirect push to="/home" />
            </Route>
            <Route exact path="/FAQ">
              <FAQ />
            </Route>
            <Route exact path="/news">
              <News />
            </Route>
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
