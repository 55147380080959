import React from "react";
import { Jumbotron, Container, Row, Col, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookSquare,
  faInstagramSquare,
} from "@fortawesome/free-brands-svg-icons";
import imgAbout from "../img/aboutUs.svg";
import imgContact from "../img/contactUs.svg";
import { Link } from "react-router-dom";
export const AboutUs = () => {
  return (
    <Jumbotron fluid>
      <Container>
        <Row>
          {/* <Col sm={8} md={6}>
            <img src={imgAbout} style={{ width: "100%" }} />
          </Col> */}
          <Col>
            <h1>About Us</h1>
            <div>
              <p>
                We are a grassroots broad-based coalition of organizations,
                artists, media, businesses, politicians, health professionals,
                allies, individuals and stakeholders who united together in the
                midst of October for one shared cause: to get Bill 104, Tamil
                Genocide Education Week passed soon.
              </p>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "60px" }}>
          <Col>
            <div style={{ color: "gray" }}>
              <h1 style={{ fontWeight: "500", color: "black" }}>Contact Us</h1>
              <FontAwesomeIcon
                style={{ color: "gray", marginRight: "5px" }}
                size="4x"
                icon={faEnvelopeSquare}
              />
              : info@coalitionforbill104.com
              <br />
              <Nav.Link
                href="https://www.instagram.com/coalition104/"
                style={{ color: "gray" }}>
                <FontAwesomeIcon href="" size="4x" icon={faFacebookSquare} />
                /coalition104
              </Nav.Link>
              <Nav.Link
                href="https://www.instagram.com/coalition104/"
                style={{ color: "gray" }}>
                <FontAwesomeIcon size="4x" icon={faInstagramSquare} />
                /coalition104
              </Nav.Link>
            </div>
          </Col>
          {/* <Col sm={8} md={6}>
            <img src={imgContact} style={{ width: "100%" }} />
          </Col> */}
        </Row>
      </Container>
    </Jumbotron>
  );
};
