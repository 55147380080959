import React, { useState, useEffect } from "react";
import { Container, Spinner, Jumbotron } from "react-bootstrap";
import { ReactTinyLink } from "react-tiny-link";

export const News = () => {
  const [data, setData] = useState("");
  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        "https://api.npoint.io/92c4bc4e4a3ae153d60e"
      );
      setData(await response.json());
    }
    fetchData();
  }, []);
  console.log(data["urls"]);
  return (
    <Container>
      <div style={{ display: "absolute" }}>
        <h1
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "20px",
            color: "red",
          }}>
          News
        </h1>
      </div>
      <p></p>
      {data === "" ? (
        <h1>
          <Spinner animation="border" variant="danger" size="lg" />
          loading
        </h1>
      ) : (
        data["urls"].map(({ url }) => (
          <div style={{ paddingBottom: "20px" }}>
            <ReactTinyLink
              cardSize="small"
              showGraphic={true}
              maxLine={3}
              minLine={1}
              url={url}
              width
            />
          </div>
        ))
      )}
    </Container>
  );
};
